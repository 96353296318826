import * as React from 'react';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/DraftbitTheme.js';

import WelcomeScreen from './screens/WelcomeScreen';
import BookProfileScreen from './screens/BookProfileScreen';
import DiscoverBooksScreen from './screens/DiscoverBooksScreen';
import Tutorial1Screen from './screens/Tutorial1Screen';
import Tutorial2Screen from './screens/Tutorial2Screen';
import TutorialScreen from './screens/TutorialScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

export default function RootAppNavigator() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerBackImage: ({ tintColor }) => (
            <Icon
              name="AntDesign/caretleft"
              size={Platform.OS === 'ios' ? 21 : 24}
              color={tintColor}
              style={[styles.headerIcon, styles.headerIconLeft]}
            />
          ),
          headerBackTitle: 'Testing',
        }}
      >
        <Stack.Screen
          name="WelcomeScreen"
          component={WelcomeScreen}
          options={{ title: 'Welcome Screen' }}
        />
        <Stack.Screen
          name="TutorialScreen"
          component={TutorialScreen}
          options={{ title: 'Tutorial Screen' }}
        />
        <Stack.Screen
          name="Tutorial1Screen"
          component={Tutorial1Screen}
          options={{ title: 'Tutorial 1' }}
        />
        <Stack.Screen
          name="DiscoverBooksScreen"
          component={DiscoverBooksScreen}
          options={{ title: 'Discover Books' }}
        />
        <Stack.Screen
          name="BookProfileScreen"
          component={BookProfileScreen}
          options={{ title: 'Book Profile' }}
        />
        <Stack.Screen
          name="Tutorial2Screen"
          component={Tutorial2Screen}
          options={{ title: 'Tutorial 2' }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({
    ios: {
      marginRight: 6,
    },
  }),
  headerIconRight: Platform.select({
    ios: {
      marginLeft: 6,
    },
  }),
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({
    ios: {
      marginLeft: 8,
    },
  }),
  headerContainerRight: Platform.select({
    ios: {
      marginRight: 8,
    },
  }),
  headerLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerLabel: {
    fontSize: 17,
    letterSpacing: 0.35,
  },
});
