import React from 'react';
import Images from '../config/Images';
import {
  Button,
  Container,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native';

const Tutorial2Screen = props => {
  const { theme } = props;
  const { navigation } = props;

  const onPressSaj8mC2b = item => {
    const data = item;
    navigation.navigate('DiscoverBooksScreen', {});
  };
  const onPresszA0s8Qr2 = item => {
    const data = item;
    navigation.navigate('Tutorial1Screen', {});
  };

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      <ImageBackground
        style={styles.ImageBackground_07}
        resizeMode="cover"
        source={Images.BookbitGradientWallpaper2}
      >
        <View
          style={styles.ViewP4}
          accessible={true}
          importantForAccessibility="auto"
          hitSlop={{}}
          pointerEvents="auto"
        >
          <View
            style={styles.ViewnW}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Container
              style={StyleSheet.flatten([
                styles.Containerua,
                {
                  borderRadius: theme.borderRadius.button,
                  backgroundColor: theme.colors.custom_rgb255_255_255,
                },
              ])}
              useThemeGutterPadding={false}
              elevation={3}
            >
              <Icon
                color={theme.colors.strong}
                size={24}
                name="MaterialIcons/format-quote"
              />
            </Container>

            <Text
              style={StyleSheet.flatten([
                styles.TextZ3,
                theme.typography.headline4,
                { color: theme.colors.strong },
              ])}
            >
              Read interesting book quotes
            </Text>
          </View>

          <View
            style={styles.ViewbE}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <View
              style={styles.ViewtM}
              accessible={true}
              importantForAccessibility="auto"
              hitSlop={{}}
              pointerEvents="auto"
            >
              <Image
                style={styles.ImagenS}
                resizeMode="cover"
                source={Images.BookbitPreview3}
              />
              <Text
                style={StyleSheet.flatten([
                  styles.TextaH,
                  theme.typography.body1,
                  { color: theme.colors.strong },
                ])}
              >
                Build up your knowledge
              </Text>
            </View>
          </View>
        </View>

        <View
          style={styles.ViewFp}
          accessible={true}
          importantForAccessibility="auto"
          hitSlop={{}}
          pointerEvents="auto"
        >
          <Container
            style={{ borderRadius: theme.borderRadius.button }}
            useThemeGutterPadding={false}
            elevation={3}
          >
            <Button
              onPress={onPressSaj8mC2b}
              style={StyleSheet.flatten([
                styles.ButtonbC,
                { borderRadius: theme.borderRadius.global },
              ])}
              type="solid"
              labelColor={theme.colors.background}
              color={theme.colors.strong}
            >
              Discover Books
            </Button>
          </Container>

          <View
            style={styles.ViewZy}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Touchable onPress={onPresszA0s8Qr2} style={styles.TouchableEj}>
              <View
                style={styles.ViewpC}
                accessible={true}
                importantForAccessibility="auto"
                hitSlop={{}}
                pointerEvents="auto"
              >
                <Icon
                  color={theme.colors.medium}
                  size={24}
                  name="MaterialIcons/navigate-before"
                />
                <Text
                  style={StyleSheet.flatten([
                    styles.TexteC,
                    theme.typography.button,
                    { color: theme.colors.medium },
                  ])}
                >
                  Prev
                </Text>
              </View>
            </Touchable>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  Containerua: {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 46,
    maxWidth: 46,
    minHeight: 46,
    maxHeight: 46,
    marginBottom: 14,
  },
  ViewnW: {
    alignItems: 'center',
  },
  ImagenS: {
    width: 250,
    height: 250,
    marginBottom: 14,
  },
  ViewtM: {
    alignItems: 'center',
  },
  ViewP4: {
    paddingLeft: 34,
    paddingRight: 34,
    paddingBottom: 42,
    paddingTop: 64,
  },
  ButtonbC: {
    minHeight: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ViewpC: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    flexDirection: 'row',
  },
  TouchableEj: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 24,
    marginLeft: 24,
  },
  ImageBackground_07: {
    height: '100%',
    flexGrow: 1,
    justifyContent: 'space-around',
  },
  ViewbE: {
    marginTop: 64,
    alignItems: 'center',
  },
  ViewZy: {
    marginTop: 14,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
  ViewFp: {
    marginTop: 42,
    marginBottom: 42,
    paddingLeft: 34,
    paddingRight: 34,
  },
  TextZ3: {
    textAlign: 'center',
  },
  TextaH: {
    textAlign: 'center',
  },
  TexteC: {
    marginLeft: 6,
  },
});

export default withTheme(Tutorial2Screen);
