import React from 'react';
import Images from '../config/Images';
import {
  Button,
  Container,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native';

const WelcomeScreen = props => {
  const { theme } = props;
  const { navigation } = props;

  const onPressa4MgdDOF = item => {
    const data = item;
    navigation.navigate('DiscoverBooksScreen', {});
  };
  const onPressyp7nzgzF = item => {
    const data = item;
    navigation.navigate('TutorialScreen', {});
  };

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <ImageBackground
        style={styles.ImageBackground_00}
        resizeMode="cover"
        source={Images.BookbitGradientWallpaper}
      >
        <View
          style={styles.ViewY5}
          accessible={true}
          importantForAccessibility="auto"
          hitSlop={{}}
          pointerEvents="auto"
        >
          <Image
            style={styles.ImagewT}
            resizeMode="cover"
            source={Images.BookbitLogoPlaceholder}
          />
          <Text
            style={StyleSheet.flatten([
              theme.typography.headline1,
              { color: theme.colors.strong },
            ])}
          >
            Bookbit
          </Text>

          <Text
            style={StyleSheet.flatten([
              styles.TextXS,
              theme.typography.subtitle1,
              { color: theme.colors.strong },
            ])}
          >
            Discover books, authors, and quotes.
          </Text>
        </View>

        <View
          style={styles.Viewmw}
          accessible={true}
          importantForAccessibility="auto"
          hitSlop={{}}
          pointerEvents="auto"
        >
          <Container
            style={{ borderRadius: theme.borderRadius.button }}
            useThemeGutterPadding={false}
            elevation={3}
          >
            <Button
              onPress={onPressa4MgdDOF}
              style={StyleSheet.flatten([
                styles.Button_9Y,
                { borderRadius: theme.borderRadius.button },
              ])}
              type="solid"
              color={theme.colors.strong}
              labelColor={theme.colors.background}
            >
              Discover Books
            </Button>
          </Container>

          <View
            style={styles.Viewpw}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Touchable onPress={onPressyp7nzgzF} style={styles.TouchableRm}>
              <Text
                style={StyleSheet.flatten([
                  theme.typography.button,
                  { color: theme.colors.medium },
                ])}
              >
                Learn More
              </Text>
            </Touchable>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ImagewT: {
    width: 120,
    height: 164,
    marginBottom: 4,
  },
  ViewY5: {
    alignItems: 'center',
    marginBottom: 120,
  },
  Button_9Y: {
    paddingLeft: 28,
    paddingRight: 28,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 54,
  },
  TouchableRm: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 42,
    width: '60%',
  },
  ImageBackground_00: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 40,
    flexGrow: 1,
  },
  Viewpw: {
    marginTop: 14,
    alignItems: 'center',
  },
  Viewmw: {
    width: '100%',
    paddingLeft: 34,
    paddingRight: 34,
  },
  TextXS: {
    marginTop: 4,
  },
});

export default withTheme(WelcomeScreen);
