import React from 'react';
import Images from '../config/Images';
import { Container, FAB, ScreenContainer, withTheme } from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import {
  Image,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const BookProfileScreen = props => {
  const { theme } = props;

  const onPressuJrlevwv = async () => {
    await WebBrowser.openBrowserAsync(
      'https://www.amazon.com/Hunger-Games-Book-1/dp/0439023521/ref=sr_1_2?crid=CEQ21P086IYE&dchild=1&keywords=the+hunger+games+book&qid=1606853658&sprefix=The+Hunger+Games+book%2Caps%2C195&sr=8-2'
    );
  };

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      <ScrollView
        contentContainerStyle={styles.ScrollViewrE}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        bounces={true}
      >
        <ImageBackground
          style={styles.ImageBackgroundKK}
          resizeMode="cover"
          source={Images.BookbitGradientWallpaper4}
        >
          <View
            style={styles.ViewPz}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Fetch
              url={`https://example-data.draftbit.com/books/${encodeURIComponent(
                props.route.params['id']
              )}`}
              headers={{
                Accept: 'application/json',
                'Content-Type': 'application/json',
              }}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) {
                  return null;
                }

                if (error) {
                  return null;
                }

                if (!data) {
                  return null;
                }

                return (
                  <View
                    style={styles.View_9i}
                    accessible={true}
                    importantForAccessibility="auto"
                    hitSlop={{}}
                    pointerEvents="auto"
                  >
                    <View
                      style={styles.ViewHC}
                      accessible={true}
                      importantForAccessibility="auto"
                      hitSlop={{}}
                      pointerEvents="auto"
                    >
                      <Container
                        style={StyleSheet.flatten([
                          styles.ContainerZx,
                          { borderRadius: theme.borderRadius.global },
                        ])}
                        useThemeGutterPadding={false}
                        elevation={3}
                      >
                        <Image
                          style={styles.Imagevy}
                          resizeMode="cover"
                          source={{ uri: data['image_url'] }}
                        />
                      </Container>

                      <View
                        style={styles.ViewWf}
                        accessible={true}
                        importantForAccessibility="auto"
                        hitSlop={{}}
                        pointerEvents="auto"
                      >
                        <Text
                          style={StyleSheet.flatten([
                            styles.TextfW,
                            theme.typography.headline3,
                            { color: theme.colors.strong },
                          ])}
                        >
                          {data && data['title']}
                        </Text>

                        <Text
                          style={StyleSheet.flatten([
                            theme.typography.subtitle1,
                            { color: theme.colors.strong },
                          ])}
                        >
                          By
                          <Text
                            style={StyleSheet.flatten([
                              styles.Text_5C,
                              theme.typography.subtitle1,
                              { color: theme.colors.strong },
                            ])}
                          >
                            {data && data['authors']}
                          </Text>
                        </Text>
                      </View>
                    </View>

                    <View
                      style={styles.View_6Q}
                      accessible={true}
                      importantForAccessibility="auto"
                      hitSlop={{}}
                      pointerEvents="auto"
                    >
                      <Text
                        style={StyleSheet.flatten([
                          styles.TextHk,
                          theme.typography.headline5,
                          { color: theme.colors.strong },
                        ])}
                      >
                        About this Book
                      </Text>

                      <Text
                        style={StyleSheet.flatten([
                          theme.typography.subtitle1,
                          { color: theme.colors.strong },
                        ])}
                      >
                        {data && data['description']}
                      </Text>
                    </View>

                    <View
                      style={styles.Viewse}
                      accessible={true}
                      importantForAccessibility="auto"
                      hitSlop={{}}
                      pointerEvents="auto"
                    >
                      <Container
                        style={StyleSheet.flatten([
                          styles.ContainerxH,
                          {
                            borderRadius: theme.borderRadius.global,
                            backgroundColor: theme.colors.background,
                          },
                        ])}
                        useThemeGutterPadding={true}
                        elevation={3}
                      >
                        <Text
                          style={StyleSheet.flatten([
                            styles.TextRS,
                            theme.typography.body1,
                            { color: theme.colors.strong },
                          ])}
                        >
                          {data && data['Quote1']}
                        </Text>
                      </Container>

                      <Container
                        style={StyleSheet.flatten([
                          styles.ContainerFu,
                          {
                            borderRadius: theme.borderRadius.global,
                            backgroundColor: theme.colors.background,
                          },
                        ])}
                        useThemeGutterPadding={true}
                        elevation={3}
                      >
                        <Text
                          style={StyleSheet.flatten([
                            styles.TextII,
                            theme.typography.body1,
                            { color: theme.colors.strong },
                          ])}
                        >
                          {data && data['Quote2']}
                        </Text>
                      </Container>

                      <Container
                        style={StyleSheet.flatten([
                          styles.Containerah,
                          {
                            borderRadius: theme.borderRadius.global,
                            backgroundColor: theme.colors.background,
                          },
                        ])}
                        useThemeGutterPadding={true}
                        elevation={3}
                      >
                        <Text
                          style={StyleSheet.flatten([
                            styles.Textlh,
                            theme.typography.body1,
                            { color: theme.colors.strong },
                          ])}
                        >
                          {data && data['Quote3']}
                        </Text>
                      </Container>
                    </View>
                  </View>
                );
              }}
            </Fetch>
          </View>
        </ImageBackground>
      </ScrollView>
      <FAB
        onPress={onPressuJrlevwv}
        style={styles.FABJP}
        label="Buy this Book"
        type="fixed"
        color={theme.colors.primary}
      />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  Imagevy: {
    width: 120,
    height: 190,
  },
  ContainerZx: {
    width: 120,
    height: 190,
    overflow: 'hidden',
    marginBottom: 24,
  },
  ViewHC: {
    alignItems: 'center',
    paddingTop: 34,
    paddingBottom: 34,
  },
  ContainerxH: {
    paddingTop: 18,
    paddingBottom: 18,
    marginBottom: 34,
  },
  View_9i: {
    width: '100%',
  },
  ViewPz: {
    alignItems: 'center',
    width: '100%',
  },
  ImageBackgroundKK: {
    height: '100%',
    paddingLeft: 32,
    paddingTop: 34,
    paddingBottom: 34,
    paddingRight: 32,
  },
  ScrollViewrE: {
    flexGrow: 1,
  },
  TextfW: {
    marginBottom: 8,
  },
  TextHk: {
    marginBottom: 8,
  },
  TextRS: {
    marginTop: 6,
    marginBottom: 6,
    textAlign: 'center',
  },
  TextII: {
    marginTop: 6,
    marginBottom: 6,
    textAlign: 'center',
  },
  Textlh: {
    marginTop: 6,
    marginBottom: 6,
    textAlign: 'center',
  },
  ViewWf: {
    alignItems: 'center',
  },
  View_6Q: {
    paddingTop: 24,
    paddingBottom: 24,
    width: '100%',
  },
  ContainerFu: {
    paddingTop: 18,
    paddingBottom: 18,
    marginBottom: 34,
  },
  FABJP: {
    left: 32,
    right: 32,
    bottom: 32,
    position: 'absolute',
    zIndex: 99,
  },
  Text_5C: {
    marginLeft: 6,
  },
  Containerah: {
    paddingTop: 18,
    paddingBottom: 18,
    marginBottom: 34,
  },
  Viewse: {
    paddingTop: 24,
    paddingBottom: 24,
    marginTop: 34,
    marginBottom: 64,
  },
});

export default withTheme(BookProfileScreen);
