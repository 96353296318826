export default {
  BookbitPreview1: require('../assets/images/BookbitPreview1.png'),
  BookbitGradientWallpaper5: require('../assets/images/BookbitGradientWallpaper5.png'),
  BookbitLogoPlaceholder: require('../assets/images/BookbitLogoPlaceholder.png'),
  BookbitGradientWallpaper: require('../assets/images/BookbitGradientWallpaper.png'),
  BookbitPreview3: require('../assets/images/BookbitPreview3.png'),
  BookbitGradientWallpaper4: require('../assets/images/BookbitGradientWallpaper4.png'),
  BookbitGradientWallpaper3: require('../assets/images/BookbitGradientWallpaper3.png'),
  BookbitGradientWallpaper2: require('../assets/images/BookbitGradientWallpaper2.png'),
  BookbitPreview2: require('../assets/images/BookbitPreview2.png'),
};
