import React from 'react';
import Images from '../config/Images';
import {
  Button,
  Container,
  Icon,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { ImageBackground, StyleSheet, Text, View } from 'react-native';

const TutorialScreen = props => {
  const { theme } = props;
  const { navigation } = props;

  const onPress9aqPrn4N = item => {
    const data = item;
    navigation.navigate('DiscoverBooksScreen', {});
  };
  const onPressssbTpeM7 = item => {
    const data = item;
    navigation.navigate('Tutorial1Screen', {});
  };

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      <ImageBackground
        style={styles.ImageBackground_8u}
        resizeMode="cover"
        source={Images.BookbitGradientWallpaper2}
      >
        <View
          style={styles.Viewrk}
          accessible={true}
          importantForAccessibility="auto"
          hitSlop={{}}
          pointerEvents="auto"
        >
          <Text
            style={StyleSheet.flatten([
              styles.TextyR,
              theme.typography.headline2,
              { color: theme.colors.strong },
            ])}
          >
            How it works
          </Text>

          <View
            style={styles.ViewUe}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <View
              style={styles.ViewA6}
              accessible={true}
              importantForAccessibility="auto"
              hitSlop={{}}
              pointerEvents="auto"
            >
              <Container
                style={StyleSheet.flatten([
                  styles.Containeru8,
                  {
                    borderRadius: theme.borderRadius.button,
                    backgroundColor: theme.colors.background,
                  },
                ])}
                useThemeGutterPadding={false}
                elevation={3}
              >
                <Icon
                  color={theme.colors.strong}
                  size={24}
                  name="Entypo/book"
                />
              </Container>

              <Text
                style={StyleSheet.flatten([
                  theme.typography.body1,
                  { color: theme.colors.strong },
                ])}
              >
                Discover new and popular books
              </Text>
            </View>
          </View>

          <View
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <View
              style={styles.ViewZJ}
              accessible={true}
              importantForAccessibility="auto"
              hitSlop={{}}
              pointerEvents="auto"
            >
              <Container
                style={StyleSheet.flatten([
                  styles.ContainerhH,
                  {
                    borderRadius: theme.borderRadius.button,
                    backgroundColor: theme.colors.background,
                  },
                ])}
                useThemeGutterPadding={false}
                elevation={3}
              >
                <Icon
                  color={theme.colors.strong}
                  size={24}
                  name="MaterialIcons/format-quote"
                />
              </Container>

              <Text
                style={StyleSheet.flatten([
                  theme.typography.body1,
                  { color: theme.colors.strong },
                ])}
              >
                Read interesting quotes and buy books
              </Text>
            </View>
          </View>
        </View>

        <View
          style={styles.View_24}
          accessible={true}
          importantForAccessibility="auto"
          hitSlop={{}}
          pointerEvents="auto"
        >
          <Container
            style={{ borderRadius: theme.borderRadius.button }}
            useThemeGutterPadding={false}
            elevation={3}
          >
            <Button
              onPress={onPress9aqPrn4N}
              style={styles.ButtonQl}
              type="solid"
              labelColor={theme.colors.background}
              color={theme.colors.strong}
            >
              Discover Books
            </Button>
          </Container>

          <View
            style={styles.ViewA7}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Button
              onPress={onPressssbTpeM7}
              style={styles.ButtoncR}
              type="outline"
              labelColor={theme.colors.medium}
            >
              Next
            </Button>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  Containeru8: {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 46,
    maxWidth: 46,
    minHeight: 46,
    maxHeight: 46,
    marginRight: 14,
  },
  ViewA6: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '90%',
  },
  ContainerhH: {
    minWidth: 46,
    maxWidth: 46,
    maxHeight: 46,
    minHeight: 46,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 14,
  },
  ViewZJ: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '90%',
  },
  Viewrk: {
    paddingLeft: 34,
    paddingRight: 34,
    paddingBottom: 42,
    paddingTop: 42,
  },
  ButtonQl: {
    minHeight: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ButtoncR: {
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },
  ImageBackground_8u: {
    height: '100%',
    flexGrow: 1,
    justifyContent: 'space-around',
  },
  TextyR: {
    marginBottom: 64,
  },
  ViewUe: {
    marginBottom: 34,
  },
  ViewA7: {
    marginTop: 14,
  },
  View_24: {
    marginTop: 80,
    marginBottom: 80,
    paddingLeft: 34,
    paddingRight: 34,
  },
});

export default withTheme(TutorialScreen);
