import React from 'react';
import Images from '../config/Images';
import {
  Container,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  FlatList,
  Image,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const DiscoverBooksScreen = props => {
  const { theme } = props;
  const { navigation } = props;

  const onPressBHHmoxFi = item => {
    const data = item;
    navigation.navigate('BookProfileScreen', { id: item['id'] });
  };
  const onPresshbj6rgVl = item => {
    const data = item;
    navigation.navigate('BookProfileScreen', { id: item['id'] });
  };
  const onPressgbPR2Vmu = item => {
    const data = item;
    navigation.navigate('BookProfileScreen', { id: data['id'] });
  };

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      <ImageBackground
        style={styles.ImageBackgroundsP}
        resizeMode="cover"
        source={Images.BookbitGradientWallpaper3}
      >
        <ScrollView
          contentContainerStyle={styles.ScrollViewxn}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          bounces={true}
        >
          <View
            style={StyleSheet.flatten([
              styles.Viewi8,
              { backgroundColor: theme.colors.primary },
            ])}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Text
              style={StyleSheet.flatten([
                theme.typography.headline3,
                { color: theme.colors.custom_rgb255_255_255 },
              ])}
            >
              Discover Books
            </Text>
          </View>

          <View
            style={styles.ViewLP}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Text
              style={StyleSheet.flatten([
                styles.TextES,
                theme.typography.headline4,
                { color: theme.colors.strong },
              ])}
            >
              Book of the Day
            </Text>
            <Fetch
              url={`https://example-data.draftbit.com/books/${encodeURIComponent(
                101
              )}`}
              headers={{
                Accept: 'application/json',
                'Content-Type': 'application/json',
              }}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) {
                  return null;
                }

                if (error) {
                  return null;
                }

                if (!data) {
                  return null;
                }

                return (
                  <View
                    style={styles.View_6m}
                    accessible={true}
                    importantForAccessibility="auto"
                    hitSlop={{}}
                    pointerEvents="auto"
                  >
                    <View
                      accessible={true}
                      importantForAccessibility="auto"
                      hitSlop={{}}
                      pointerEvents="auto"
                    >
                      <Container
                        style={StyleSheet.flatten([
                          styles.ContainerCe,
                          { borderRadius: theme.borderRadius.global },
                        ])}
                        useThemeGutterPadding={false}
                        elevation={3}
                      >
                        <Image
                          style={StyleSheet.flatten([
                            styles.ImagefD,
                            { borderRadius: theme.borderRadius.global },
                          ])}
                          resizeMode="cover"
                          source={{ uri: data['image_url'] }}
                        />
                      </Container>
                    </View>

                    <View
                      style={styles.ViewfK}
                      accessible={true}
                      importantForAccessibility="auto"
                      hitSlop={{}}
                      pointerEvents="auto"
                    >
                      <Text
                        style={StyleSheet.flatten([
                          styles.Texttd,
                          theme.typography.headline5,
                          { color: theme.colors.strong },
                        ])}
                      >
                        {data && data['title']}
                      </Text>

                      <Text
                        style={StyleSheet.flatten([
                          theme.typography.subtitle2,
                          { color: theme.colors.medium },
                        ])}
                      >
                        {data && data['authors']}
                      </Text>

                      <Touchable
                        onPress={() => onPressgbPR2Vmu(data)}
                        style={styles.TouchableEq}
                      >
                        <View
                          style={styles.ViewrS}
                          accessible={true}
                          importantForAccessibility="auto"
                          hitSlop={{}}
                          pointerEvents="auto"
                        >
                          <Text
                            style={StyleSheet.flatten([
                              styles.TextSw,
                              { color: theme.colors.primary },
                            ])}
                          >
                            View
                          </Text>
                          <Icon
                            color={theme.colors.primary}
                            size={18}
                            name="MaterialCommunityIcons/chevron-right-circle"
                          />
                        </View>
                      </Touchable>
                    </View>
                  </View>
                );
              }}
            </Fetch>
          </View>

          <View
            style={styles.View_36}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Text
              style={StyleSheet.flatten([
                styles.Textuk,
                theme.typography.headline4,
                { color: theme.colors.strong },
              ])}
            >
              Trending
            </Text>
            <Fetch
              url={`https://example-data.draftbit.com/books?_limit=10&_order=desc&_sort=rating_count`}
              headers={{
                Accept: 'application/json',
                'Content-Type': 'application/json',
              }}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) {
                  return null;
                }

                if (error) {
                  return null;
                }

                if (!data) {
                  return null;
                }

                return (
                  <FlatList
                    renderItem={({ item }) => (
                      <View
                        style={styles.View_2d}
                        accessible={true}
                        importantForAccessibility="auto"
                        hitSlop={{}}
                        pointerEvents="auto"
                      >
                        <Touchable onPress={() => onPresshbj6rgVl(item)}>
                          <View
                            accessible={true}
                            importantForAccessibility="auto"
                            hitSlop={{}}
                            pointerEvents="auto"
                          >
                            <Container
                              style={StyleSheet.flatten([
                                styles.ContainerfP,
                                { borderRadius: theme.borderRadius.global },
                              ])}
                              useThemeGutterPadding={false}
                              elevation={3}
                            >
                              <Image
                                style={styles.ImageRn}
                                resizeMode="cover"
                                source={{ uri: item['image_url'] }}
                              />
                            </Container>

                            <View
                              style={styles.ViewxN}
                              accessible={true}
                              importantForAccessibility="auto"
                              hitSlop={{}}
                              pointerEvents="auto"
                            >
                              <Text
                                style={StyleSheet.flatten([
                                  theme.typography.subtitle2,
                                  { color: theme.colors.strong },
                                ])}
                                numberOfLines={2}
                              >
                                {item && item['title']}
                              </Text>
                            </View>
                          </View>
                        </Touchable>
                      </View>
                    )}
                    contentContainerStyle={styles.FlatListLR}
                    numColumns={1}
                    horizontal={true}
                    data={data}
                  />
                );
              }}
            </Fetch>
          </View>

          <View
            style={styles.Viewol}
            accessible={true}
            importantForAccessibility="auto"
            hitSlop={{}}
            pointerEvents="auto"
          >
            <Text
              style={StyleSheet.flatten([
                styles.Textug,
                theme.typography.headline4,
                { color: theme.colors.strong },
              ])}
            >
              Newly Added
            </Text>
            <Fetch
              url={`https://example-data.draftbit.com/books?_page=1`}
              headers={{
                Accept: 'application/json',
                'Content-Type': 'application/json',
              }}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) {
                  return null;
                }

                if (error) {
                  return null;
                }

                if (!data) {
                  return null;
                }

                return (
                  <FlatList
                    renderItem={({ item }) => (
                      <View
                        style={styles.View_6B}
                        accessible={true}
                        importantForAccessibility="auto"
                        hitSlop={{}}
                        pointerEvents="auto"
                      >
                        <Touchable onPress={() => onPressBHHmoxFi(item)}>
                          <View
                            accessible={true}
                            importantForAccessibility="auto"
                            hitSlop={{}}
                            pointerEvents="auto"
                          >
                            <Container
                              style={StyleSheet.flatten([
                                styles.ContainerMs,
                                { borderRadius: theme.borderRadius.global },
                              ])}
                              useThemeGutterPadding={false}
                              elevation={3}
                            >
                              <Image
                                style={styles.ImagehX}
                                resizeMode="cover"
                                source={{ uri: item['image_url'] }}
                              />
                            </Container>

                            <View
                              style={styles.Viewf5}
                              accessible={true}
                              importantForAccessibility="auto"
                              hitSlop={{}}
                              pointerEvents="auto"
                            >
                              <Text
                                style={StyleSheet.flatten([
                                  theme.typography.subtitle2,
                                  { color: theme.colors.strong },
                                ])}
                                numberOfLines={2}
                              >
                                {item && item['title']}
                              </Text>
                            </View>
                          </View>
                        </Touchable>
                      </View>
                    )}
                    contentContainerStyle={styles.FlatListOe}
                    numColumns={1}
                    data={data}
                    horizontal={true}
                  />
                );
              }}
            </Fetch>
          </View>
        </ScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ImagefD: {
    width: 150,
    height: 220,
  },
  ContainerCe: {
    overflow: 'hidden',
    marginBottom: 14,
  },
  ImagehX: {
    width: 80,
    height: 118,
  },
  ContainerMs: {
    width: 80,
    height: 118,
    overflow: 'hidden',
  },
  ViewrS: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  View_6m: {
    alignItems: 'center',
  },
  View_6B: {
    marginRight: 32,
  },
  ImageRn: {
    width: 80,
    height: 118,
  },
  ContainerfP: {
    width: 80,
    height: 118,
    overflow: 'hidden',
  },
  View_2d: {
    marginRight: 32,
  },
  FlatListOe: {
    flexDirection: 'row',
    paddingLeft: 32,
  },
  FlatListLR: {
    flexDirection: 'row',
    paddingLeft: 32,
  },
  Viewi8: {
    alignItems: 'center',
    marginBottom: 14,
    paddingBottom: 28,
    paddingTop: 24,
    justifyContent: 'center',
  },
  ScrollViewxn: {
    paddingBottom: 34,
  },
  ImageBackgroundsP: {
    height: 1000,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  Texttd: {
    marginBottom: 2,
  },
  TextSw: {
    marginRight: 6,
  },
  TextES: {
    marginBottom: 18,
    textAlign: 'center',
  },
  Textuk: {
    marginBottom: 18,
    textAlign: 'center',
  },
  Textug: {
    marginBottom: 18,
    textAlign: 'center',
  },
  Viewf5: {
    width: 80,
    marginTop: 10,
  },
  ViewfK: {
    alignItems: 'center',
  },
  ViewxN: {
    marginTop: 10,
    width: 80,
  },
  ViewLP: {
    paddingTop: 34,
    paddingBottom: 34,
  },
  TouchableEq: {
    marginTop: 12,
  },
  View_36: {
    paddingBottom: 34,
    paddingTop: 34,
  },
  Viewol: {
    paddingBottom: 34,
    paddingTop: 34,
  },
});

export default withTheme(DiscoverBooksScreen);
